<template>
  <b-card>

    <b-card-title>
      {{ $t('Partners') }}
    </b-card-title>

    <b-card-text>
      <b-row class="d-flex align-items-center justify-content-between">

        <b-col md="12">
          <b-button
            to="/partners/create"
            size="sm"
            variant="info"
          >
            {{ $t('Create New Partner') }}
          </b-button>
        </b-col>

        <b-col md="auto">
          <b-button
            size="sm"
            :variant="isActivatePartner ? 'danger' : 'success'"
            @click="isActivatePartner = !isActivatePartner"
          >
            {{ $t('Show') }} {{ isActivatePartner ? $t('Inactivate Partners') : $t('Activate Partners') }}
          </b-button>
        </b-col>

        <b-col
          md="2"
          sm="auto"
          class="my-1"
        >
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ $t('Per page') }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>

        <b-col
          md="4"
          class="my-1"
        >
          <b-form-group
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                :placeholder="$t('Type to Search')"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  {{ $t('clear') }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          class="table-container"
        >

          <b-table
            class="text-capitalize"
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            show-empty
            :busy="isLoading"
            :items="isActivatePartner == true ? activatePartners : inactivePartners"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(partnerIdType)="data">
              <b-badge
                class="font-weight-normal"
                variant="success"
              >
                {{ data.item.partnerIdType }}
              </b-badge>
            </template>

            <template #cell(partnerInfo.IndividualPartner.email)="data">
              <b-badge
                class="text-lowercase font-weight-normal"
                variant="info"
              >
                {{ data.item.partnerInfo.data.email }}
              </b-badge>
            </template>

            <template #table-busy>
              <div class="text-center">
                <b-spinner label="Loading..." />
              </div>
            </template>

            <template #cell(actions)="data">
              <b-button
                v-if="!isActivatePartner"
                size="sm"
                variant="success"
                class="w-100"
              >
                {{ $t('Activate') }}
              </b-button>
              <b-button
                v-if="isActivatePartner"
                size="sm"
                variant="danger"
                class="w-100"
              >
                {{ $t('Inactivate') }}
              </b-button>
              <b-button
                v-if="isActivatePartner"
                size="sm"
                variant="dark"
                class="w-100 mt-1"
              >
                {{ $t('Reset Password') }}
              </b-button>
            </template>

          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            class="my-0"
          />
        </b-col>

      </b-row>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BSpinner, BTable, BAvatar, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton,
} from 'bootstrap-vue'

import useJwt from '@/auth/useJwt'
import store from '@/store'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BSpinner,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  data() {
    return {
      isActivatePartner: true,

      activatePartners: [],
      inactivePartners: [],

      isLoading: false,
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'partnerInfo.data.companyName', label: this.$t('PartnerName'), sortable: true },
        { key: 'partnerInfo.data.username', label: this.$t('Username'), sortable: true },
        { key: 'partnerInfo.data.taxId', label: this.$t('taxNumber'), sortable: false },
        { key: 'partnerInfo.data.contactLastName', label: this.$t('Last Name'), sortable: true },
        { key: 'partnerInfo.data.contactFirstName', label: this.$t('First Name'), sortable: true },
        { key: 'partnerInfo.data.contactEmail', label: this.$t('Email'), sortable: false },
        { key: 'partnerInfo.data.phoneNumber', label: this.$t('phoneNumber'), sortable: false },
        { key: 'actions', label: this.$t('Actions'), sortable: false },
      ],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    isRtl() {
      return store.state.appConfig.layout.isRTL
    },
  },
  watch: {
    isActivatePartner(val) {
      this.onFiltered(val ? this.activatePartners : this.inactivePartners)
    },
    isRtl: {
      deep: true,
      handler() {
        this.refreshFields()
      },

    },
  },
  created() {
    this.getPartners()
  },
  mounted() {},
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getPartners() {
      this.isLoading = true
      useJwt.get('/qpartner/api/domain/partners')
        .then(({ data }) => {
          this.activatePartners = data.data.activatePartners
          this.inactivePartners = data.data.inactivePartners
          this.totalRows = data.data.activatePartners.length
        })
        .catch(err => {
          console.error()
        })
        .finally(() => this.isLoading = false)
    },
    refreshFields() {
      this.fields = [
        { key: 'partnerInfo.data.companyName', label: this.$t('PartnerName'), sortable: true },
        { key: 'partnerInfo.data.username', label: this.$t('Username'), sortable: true },
        { key: 'partnerInfo.data.taxId', label: this.$t('taxNumber'), sortable: false },
        { key: 'partnerInfo.data.contactLastName', label: this.$t('Last Name'), sortable: true },
        { key: 'partnerInfo.data.contactFirstName', label: this.$t('First Name'), sortable: true },
        { key: 'partnerInfo.data.contactEmail', label: this.$t('Email'), sortable: false },
        { key: 'partnerInfo.data.phoneNumber', label: this.$t('phoneNumber'), sortable: false },
        { key: 'actions', label: this.$t('Actions'), sortable: false },
      ]
    },
  },
}
</script>
